import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  ResolutionCheckerContainer,
  ResolutionCheckerContent,
  HeaderCaptionTypography,
  TitleTypography,
  BodyTypography,
} from './styles';

const title =
  "La résolution de cet écran est malheureusement insuffisante pour profiter de l’expérience.";
const body =
  'Si vous êtes sur une tablette, vous pouvez essayer de la pivoter. Sinon, merci de réessayer avec un ordinateur.';
const headerCaption = 'Les Lignes du temps du Québec';

const ResolutionChecker = (props) => {
  const { children } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('1000'));

  return isMobile ? (
    <ResolutionCheckerContainer>
      <HeaderCaptionTypography>{headerCaption}</HeaderCaptionTypography>
      <ResolutionCheckerContent>
        <div>
          <svg
            width='70'
            height='70'
            viewBox='0 0 70 70'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle cx='35' cy='35' r='35' fill='#7E8BC3' />
            <path
              d='M36.8809 39.459H33.207L32.8027 21.4062H37.3027L36.8809 39.459ZM32.6797 44.9785C32.6797 44.3105 32.8965 43.7598 33.3301 43.3262C33.7637 42.8809 34.3555 42.6582 35.1055 42.6582C35.8555 42.6582 36.4473 42.8809 36.8809 43.3262C37.3145 43.7598 37.5312 44.3105 37.5312 44.9785C37.5312 45.623 37.3203 46.1621 36.8984 46.5957C36.4766 47.0293 35.8789 47.2461 35.1055 47.2461C34.332 47.2461 33.7344 47.0293 33.3125 46.5957C32.8906 46.1621 32.6797 45.623 32.6797 44.9785Z'
              fill='black'
            />
          </svg>
        </div>
        <TitleTypography>{title}</TitleTypography>
        <BodyTypography elementTheme='h5'>{body}</BodyTypography>
      </ResolutionCheckerContent>
    </ResolutionCheckerContainer>
  ) : (
    children
  );
};

export default ResolutionChecker;
